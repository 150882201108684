const contacts = {
  data: [
    {
      title: 'Email',
      info: 'ngantn1994@gmail.com',
    },
    {
      title: 'Skype',
      info: 'live:ngantn1994',
    },
    {
      title: 'Linkedin',
      info: 'in/ngantn1994/',
    },
    {
      title: 'Tel (Japan)',
      info: '(+81)7032862687',
    },
  ],
};

export default contacts;
